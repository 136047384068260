import { SVGAttributes } from 'react'

const FlagIcon = (props: SVGAttributes<SVGElement>) => (
    <svg viewBox="0 0 14 20" xmlns="http://www.w3.org/2000/svg" {...props}>
        <defs>
            <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id={props.id || 'flag'}>
                <stop offset="0%" stopColor="var(--stop-color-1)" />
                <stop offset="100%" stopColor="var(--stop-color-2)" />
            </linearGradient>
        </defs>
        <path
            fill={props.className?.indexOf('fill-') !== -1 ? '' : `url(#${props.id || 'flag'})`}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 1.52344V10.6055C14 10.7487 13.9271 10.8724 13.7812 10.9766C12.9609 11.4388 12.0616 11.6699 11.0833 11.6699C10.6884 11.6699 10.2372 11.6243 9.72982 11.5332C9.22243 11.4421 8.7895 11.3477 8.43099 11.25C8.07248 11.1523 7.59549 11.0124 7 10.8301C6.39843 10.6543 5.9184 10.5176 5.5599 10.4199C5.20139 10.3223 4.76845 10.2279 4.26107 10.1367C3.75369 10.0456 3.30556 10 2.91667 10C2.8559 10 2.75868 10.0033 2.625 10.0098C2.49132 10.0163 2.3941 10.0195 2.33333 10.0195V19.5801C2.33333 19.6973 2.29536 19.7965 2.2194 19.8779C2.14345 19.9593 2.05078 20 1.94141 20H0.391927C0.282552 20 0.189888 19.9593 0.113933 19.8779C0.0379774 19.7965 0 19.6973 0 19.5801V1.06445C0 0.921224 0.0638015 0.804037 0.191406 0.712891C1.0178 0.237628 1.92621 0 2.91667 0C3.30556 0 3.75369 0.0455727 4.26107 0.136719C4.76845 0.227865 5.20139 0.322266 5.5599 0.419922C5.9184 0.517579 6.39843 0.654296 7 0.830078C7.59549 1.01237 8.07248 1.15234 8.43099 1.25C8.7895 1.34766 9.22243 1.44206 9.72982 1.5332C10.2372 1.62435 10.6884 1.66992 11.0833 1.66992C11.934 1.66992 12.727 1.49089 13.4622 1.13281C13.602 1.07422 13.7235 1.09049 13.8268 1.18164C13.9423 1.25326 14 1.36719 14 1.52344Z"
        />
    </svg>
)

export default FlagIcon
